import React, { useEffect, useRef } from "react";
import gsap from "gsap";

import Landing01 from "../images/home/img_landing_01.webp";
import Landing02 from "../images/home/img_landing_02.webp";
import Landing03 from "../images/home/img_landing_03.webp";
import Landing04 from "../images/home/img_landing_04.webp";
import Landing05 from "../images/home/img_landing_05.webp";

import styled from "styled-components";
import { device } from "../globalStyles";

import Card from "./Card";
import I18N from "../constants/languages/NL/nl_translations";
import { useLangContext } from "../context/langContext";
import { useIntroContext } from "../context/introContext";

const CardsContainer = styled.div`
  position: absolute;
  top: var(--header-height);
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  z-index: 0;

  & > div {
    position: absolute;
  }
`;

const CardContainer = styled.div`
  transform-origin: center;
  opacity: 0;

  &:nth-child(5) {
    @media (${device.minTablet}) and (max-height: 1100px) {
      //display: block;
      margin-top: -2vw;
    }
    @media (max-height: 755px) or (max-width: 400px) {
      margin-top: -5vw;
    }
    @media (max-height: 700px) or (max-width: 400px) {
      margin-top: -7.5vw;
    }
    @media (max-height: 650px) or (max-width: 400px) {
      margin-top: -10vw;
    }
  }

  &:nth-child(3),
  &:nth-child(4) {
    @media (max-height: 700px) {
      //display: block;
      margin-bottom: -3vw;
    }
  }
`;

const HomeCard = styled(Card)`
  width: min(27vw, 29vh);
  height: min(37vw, 37vh);
  border-radius: 4px;

  img {
    width: 100%;
  }

  @media (${device.minTablet}) {
    padding: 8px 8px 48px;
    width: min(25vw, 32vh);
    height: min(32vw, 40vh);
  }

  @media (${device.minLaptop}) {
    padding: 12px 12px 64px;
    width: min(20vw, 32vh);
    height: min(25vw, 40vh);
  }
`;

type HomeCardsProps = {
  onAnimationComplete: () => void;
};

const CARD_START_STYLES = [
  { rotate: 180, left: "150%", top: "-50%", xPercent: -50, yPercent: -50 },
  { rotate: -180, left: "-50%", top: "-50%", xPercent: -50, yPercent: -50 },
  { rotate: -180, left: "150%", bottom: "-50%", xPercent: -50, yPercent: 50 },
  { rotate: 180, left: "-50%", bottom: "-50%", xPercent: -50, yPercent: 50 },
  { rotate: -180, left: "30%", top: "-50%", xPercent: -50, yPercent: -50 },
];
const CARD_END_STYLES = [
  { rotate: 22, left: "80%", top: 0, xPercent: 0, yPercent: -20 },
  { rotate: -14.5, left: "0%", top: 0, xPercent: -7.5, yPercent: -20 },
  { rotate: -13, left: "65%", bottom: 0, xPercent: 0, yPercent: 25 },
  { rotate: 8, left: "10%", bottom: 0, xPercent: 0, yPercent: 25 },
  { rotate: -4, left: "36%", top: 0, xPercent: 0, yPercent: -40 },
];

const HomeCards = ({ onAnimationComplete }: HomeCardsProps) => {
  const { introPlayed, setIntroPlayed } = useIntroContext();
  const { lang } = useLangContext();
  const copy = I18N[lang].landing;

  const card1Ref = useRef();
  const card2Ref = useRef();
  const card3Ref = useRef();
  const card4Ref = useRef();
  const card5Ref = useRef();

  useEffect(() => {
    const cards = [
      card1Ref.current,
      card2Ref.current,
      card3Ref.current,
      card4Ref.current,
      card5Ref.current,
    ];
    if (introPlayed) {
      cards.forEach((card, index) => {
        gsap.set(card, {
          ...CARD_END_STYLES[index],
        });
        onAnimationComplete();
      });
    } else {
      cards.forEach((card, index) => {
        gsap.set(card, {
          ...CARD_START_STYLES[index],
          opacity: 0,
        });
      });
      const tl = gsap.timeline({
        onComplete: () => {
          setIntroPlayed(true);
          onAnimationComplete();
        },
      });

      cards.forEach((card, index) => {
        tl.fromTo(
          card,
          {
            opacity: 0,
            filter: "blur(5px)",
          },
          {
            opacity: 1,
            filter: "blur(0px)",
            ...CARD_END_STYLES[index],
            ease: "Quad.easeOut",
            duration: 0.9,
          },
          "<+=0.2"
        );
      });

      return () => {
        tl.kill();
      };
    }
  }, []);

  return (
    <CardsContainer>
      <CardContainer ref={card1Ref}>
        <HomeCard>
          <img src={Landing01} alt={copy.alt1} />
        </HomeCard>
      </CardContainer>
      <CardContainer ref={card2Ref}>
        <HomeCard>
          <img src={Landing02} alt={copy.alt2} />
        </HomeCard>
      </CardContainer>

      <CardContainer ref={card3Ref}>
        <HomeCard>
          <img src={Landing03} alt={copy.alt3} />
        </HomeCard>
      </CardContainer>

      <CardContainer ref={card4Ref}>
        <HomeCard>
          <img src={Landing04} alt={copy.alt4} />
        </HomeCard>
      </CardContainer>

      <CardContainer ref={card5Ref}>
        <HomeCard>
          <img src={Landing05} alt={copy.alt5} />
        </HomeCard>
      </CardContainer>
    </CardsContainer>
  );
};

export default HomeCards;
