import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import Footer from "../components/Footer";
import Button from "../components/Button";
import HomeCards from "../components/HomeCards";
import Seo from "../components/SEO";

import MakingMemories from "../svg/logo/Making_memories.svg";

import { device } from "../globalStyles";

import { useLangContext } from "../context/langContext";

import I18N from "../constants/languages/NL/nl_translations";

const StyledLayout = styled(Layout)`
  background: var(--blue);
  color: white;
  overflow: hidden;

  --footer-height: 248px;

  button {
    //width: 100%;
  }
`;

const Content = styled.div`
  position: relative;
  padding: 0 var(--gutter);
  min-height: calc(
    100 * var(--vh) - var(--footer-height) - var(--header-height)
  );
  z-index: 1;
  //margin: calc(-1 * var(--header-height)) 0;
  //margin-bottom: calc(-1 * var(--header-height));
  //margin-top: calc(-1 * var(--header-height));
`;

const TextContainer = styled.div`
  margin: auto;
  max-width: 910px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100 * var(--vh) - var(--header-height));

  &.hidden {
    opacity: 0;
  }

  & > * {
    z-index: 2;
  }

  & > svg {
    width: 100%;
    margin-bottom: 0;

    @media (min-height: 600px) {
      margin-bottom: 32px;
    }
  }
`;

const FixedFooter = styled(Footer)`
  @media (${device.minTablet}) {
    bottom: 0;
    left: 0;
    position: fixed;
  }
`;

const StyledLink = styled(Link)`
  margin: 20px auto 0;
  text-decoration: none;
  width: 100%;

  @media (${device.minMobileL}) {
    width: unset;
  }
  @media (min-height: 550px) {
    margin-top: 40px;
  }

  & > button {
    width: 100%;
    @media (${device.minMobileL}) {
      width: 200px;
    }
  }
`;

const LandingPage = () => {
  const introTextRef = useRef();
  const footerRef = useRef();
  const [showContent, setShowContent] = useState(false);
  const { lang } = useLangContext();
  const copy = I18N[lang].landing;
  let uid;
  if (typeof window !== 'undefined') {
    uid = (new URLSearchParams(window.location.search)).get("uid");
  }

  function handleShowContent() {
    setShowContent(true);
  }

  useEffect(() => {
    if (!footerRef?.current) return;
    gsap.set(footerRef.current, { opacity: 0 });
    gsap.set(introTextRef.current, { opacity: 0 });
  }, [footerRef]);

  useEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(
      [introTextRef.current, footerRef.current],
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        ease: "Power3.easeInOut",
      }
    );
  }, []);

  return (
    <StyledLayout title="KLM Making memories" pageName="Landing" home>
      <Content>
        <TextContainer ref={introTextRef}>
          <MakingMemories />
          <h3>{copy.title}</h3>
          <StyledLink to={`/destinations?uid=${uid}`}>
            <Button>{copy.button}</Button>
          </StyledLink>
        </TextContainer>
      </Content>

      <HomeCards onAnimationComplete={handleShowContent} />

      <div ref={footerRef}>
        <FixedFooter />
      </div>
    </StyledLayout>
  );
};

export default LandingPage;

export const Head = () => <Seo />;
